import "./index.css";
import * as process from "process";

function initCheck() {
  if (process.env.DEBUG_LOG === "true") {
    console.debug("--------- initCheck ---------");
  }

  setTimeout(() => {
    const loadingElement = document.getElementById("welcome-header");

    if (loadingElement && loadingElement.style.display !== "none" && !loadingElement.classList.contains("error-message")) {
      const errorDiv = document.createElement("div");
      errorDiv.classList.add("not-supported");

      const messageElement = document.createElement("h1");
      messageElement.classList.add("not-supported-error-msg");
      messageElement.innerHTML = "An error occured while loading the add-in. Note that the Office versions that use legacy (IE or Edge) webviews are not supported. <br/>You can try to <a class='reload-add-in' href='#' onclick='window.location.reload();'>reload your add-in</a> or contact support@zebrabi.com for more info.";

      errorDiv.appendChild(messageElement);
      document.body.appendChild(errorDiv);
    }
  }, 1000 * 15);
}

initCheck();
