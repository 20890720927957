// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-supported {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 1000000;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

.not-supported-error-msg {
  font-size: 18px;
  text-align: center;
  padding: 0px 20px;
}

a.reload-add-in {
  color: revert;
  -webkit-text-decoration: revert;
          text-decoration: revert;
}`, "",{"version":3,"sources":["webpack://./packages/init-check/index.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;EACb,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,+BAAuB;UAAvB,uBAAuB;AACzB","sourcesContent":[".not-supported {\n  width: 100vw;\n  height: 100vh;\n  position: absolute;\n  z-index: 1000000;\n  background: white;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.not-supported-error-msg {\n  font-size: 18px;\n  text-align: center;\n  padding: 0px 20px;\n}\n\na.reload-add-in {\n  color: revert;\n  text-decoration: revert;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
